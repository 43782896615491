<template>
  <div id="CommonFilesTable">
    <div class="top">
      <div><span></span> 附件</div>
    </div>
    <el-table :data="data" border>
      <template #empty>
        <NoData></NoData>
      </template>
      <el-table-column label="序号" width="64" align="center" >
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="附件名字" prop="fileName"></el-table-column>
      <el-table-column label="操作" width="64" align="center">
        <template #default="scope">
          <el-button v-if="action === 'delete'" color="#0072EF" type="text" @click="deleteClick(scope.$index)">删除</el-button>
          <el-button v-if="action === 'download'" color="#0072EF" type="text" @click="downLoadClick(scope.row)">下载</el-button>
        </template>
      </el-table-column>
    </el-table> 
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      require: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    deleteClick(index) {
      this.$emit('deleteClick', index)
    },
    downLoadClick(item) {
      if(!this.$store.state.loginIn) return this.$store.commit('open_type', 1)
      window.location = this.$API.DownloadFile({
        fileName: item.fileName,
        path: item.url
      })
    }
  }
}
</script>

<style lang="scss">
  #CommonFilesTable {
    padding: 0 0 10px;

    .top {
      padding: 15px 0;

      & > div {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: center;

        & > span {
          width: 8px;
          height: 20px;
          background: #0072EF;
          border-radius: 2px;
          display: inline-block;
          margin-right: 15px;
        }
      }

    }

    .el-table {
      margin: 10px 0;
    }

    th, td {
      height: 40px;
      padding: 0;
    }
  }
</style>
