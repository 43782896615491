export const CONST = {
  userStatus: [
    { label: '全部', value: 'ALL' },
    { label: '正常', value: '1' },
    { label: '禁用', value: '0' }
  ],
  sortList: [
    { label: '按入库日期倒序', value: 'ALL' },
    { label: '按发布日期升序', value: '0' },
    { label: '按发布日期降序', value: '1' },
    { label: '按实施日期升序', value: '2' },
    { label: '按实施日期降序', value: '3' },
  ],
  statesList: [
    { label: '现行', value: 'ST' },
    { label: '未现行', value: 'NST' },
    { label: '废止', value: 'WT' },
  ],
  articleList: [
    { label: '通知公告', value: '0' },
    { label: '行业资讯', value: '1' },
    { label: '招贤纳士', value: '2' },
  ],
  commonlist: [
    { label: '是', value: '1' },
    { label: '否', value: '0' },
  ],
  shiXiaoList: [
    { label: '现行有效', value: '1' },
    { label: '失效', value: '0' },
    { label: '已被修改', value: '2' },
    { label: '部分失效', value: '3' },
  ],
  standardTypeList: [
    { label: '国家标准', value: 'ZGJ' },
    { label: '行业标准', value: 'ZHY' },
    { label: '地方标准', value: 'ZDF' },
    { label: '团体标准', value: 'ZTT' },
    { label: '国际标准', value: 'GJ' },
    { label: '国外标准', value: 'GW' },
  ]
}