<template>
  <div id="policiesRegulationsDetail" v-wechat-title="detail.title + ' - 社会团体标准化专业人员公共服务平台'">
    <CommonBread :dataList="dataList"></CommonBread>
    <div class="content">
      <h2>{{ detail.title }}</h2>
      <UnderlineShow :data="data" :detail="detail"></UnderlineShow>
      <div class="w-e-text" v-html="detail.content"></div>
      <CommonFilesTable v-if="urlList.length > 0" :data="urlList" action="download"></CommonFilesTable>
      <p class="tip">免责声明：本网页所发布内容仅供参考，原文内容请以官方发布为准</p>
    </div>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread'
import CommonFilesTable from '@/components/CommonFilesTable'
import UnderlineShow from './components/UnderlineShow'
import utils from '@/assets/js/utils'
import { CONST } from '@/assets/js/const'
export default {
  components: {
    CommonBread,
    UnderlineShow,
    CommonFilesTable
  },
  data() {
    return {
      dataList: [
        { title: '政策法规', url: '/knowledgeBase/POLICY' },
        { title: '详情' },
      ],
      detail: {},
      data: [
        { title: '制定机关', key: 'publishedSource' },
        { title: '公布日期', key: 'publishedDate' },
        { title: '文号', key: 'number' },
        { title: '施行日期', key: 'implementDate' },
        { title: '效力等级', key: 'levelEffectiveness' },
        { title: '时效性', key: 'policiesStatus' },
      ],
      urlList: []
    }
  },
  computed: {
    CONST() { return CONST }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.RegulationsDetail({
        id: parseInt(this.$route.params.id)
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.publishedDate = utils.formatDate(res.data.data.publishedDate)
          res.data.data.implementDate = utils.formatDate(res.data.data.implementDate)
           res.data.data.policiesStatus = CONST.shiXiaoList.filter(item => item.value == res.data.data.policiesStatus)[0].label
          this.detail = res.data.data;
          this.urlList = res.data.data.attachmentList || [];
        } 
      })
    }
  }
}
</script>

<style lang='scss'>
  #policiesRegulationsDetail {
    width: 1200px;
    padding: 0 10px;
    margin: 0 auto 30px;

    .content {
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 10px;
      padding: 50px 100px;

      & > h2 {
        font-size: 30px;
        color: #000000;
        line-height: 42px;
        margin-bottom: 40px;
      }

      .tip {
        font-size: 16px;
        color: #909399;
        line-height: 22px;
        margin-top: 50px;
        text-align: center;
      }
    }
  }
</style>